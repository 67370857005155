<template>
    <div>
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">园区管理</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">账单推送统计</a>
            </el-breadcrumb-item>
            </el-breadcrumb>
        <el-divider></el-divider>        
        <div v-show="queryFormVisible">
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="缴费单位" prop="companyId">
                <el-select-tree
                    size="mini"
                    :props="props"
                    :options="treeData"
                    v-model="queryModel.companyId"
                    height="200"
                ></el-select-tree>
            </el-form-item>
            <el-form-item label="缴费状态" prop="payStatus">
                <el-select v-model="queryModel.payStatus"  size="mini"  >
                    <el-option
                        v-for="payStatus in payStatusList"
                        :key="payStatus.id"
                        :label="payStatus.name"
                        :value="payStatus.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="缴费方式" prop="payName">
                <el-select v-model="queryModel.payName"  size="mini"  >
                    <el-option
                        v-for="payName in payNameList"
                        :key="payName.id"
                        :label="payName.name"
                        :value="payName.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否开票" prop="invoiced">
                <el-select v-model="queryModel.invoiced"  size="mini" clearable>
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开票类型" prop="invoiceType">
                <el-select v-model="queryModel.invoiceType"  size="mini"  >
                    <el-option
                        v-for="item in invoiceTypeList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="支付时间" prop="payTimeRange">
                <el-date-picker
                    v-model="queryModel.payTimeRange"
                    type="daterange"
                    size="mini"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    value-format="yyyy-MM-dd"
                    >
                </el-date-picker>
            </el-form-item>

            <!-- <el-form-item label="收费项目：" prop="payService">
            <el-select v-model="queryModel.payService"  size="mini">
                <el-option
                    v-for="payService in payServiceList"
                    :key="payService.id"
                    :label="payService.goodsName"
                    :value="payService.id"
                ></el-option>
            </el-select>
            </el-form-item> -->
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                type="info"
                size="mini"
                style="margin-left: 8px"
                @click="handleReset('queryForm')"
                >重置</el-button>&nbsp;
            </el-form-item>
        </el-form>
        </div>
        <el-divider></el-divider>
        <el-row class="button-group">
            <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-upload2"
                @click="toggleQueryForm()"
            >{{queryFormVisible ? '隐藏' : '显示'}}查询面板</el-button>
            <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-download"
                @click="exportXls"
                >导出账单</el-button
            >
        </el-row>
        <el-table 
        ref="formTable"
        :data="tableData" 
        :height="tableHeight"
        v-loading="loading" stripe>
            <el-table-column type="index" label="序号" :index="indexMethod" width="50" fixed="left"></el-table-column>
            <el-table-column prop="companyName" label="缴费单位" width="120" fixed="left"></el-table-column>
            <el-table-column prop="goodsName" label="收费项目" width="100"></el-table-column>
            <el-table-column prop="beginEndTime" label="起止时间" width="200"></el-table-column>
            <el-table-column prop="totalFee" label="总计金额（元）" width="120"></el-table-column>
            <el-table-column prop="invoiced" label="是否开票" >
                <template slot-scope="{row}">
                    <span v-if="row.invoiced" style="color:green">是</span>
                    <span v-else style="color:red">否</span>
                </template>
            </el-table-column>
            <el-table-column prop="invoiceType" label="开票类型"></el-table-column>
            <el-table-column prop="payStatus" label="缴费状态" >
                <template slot-scope="{row}">
                    <span v-if="row.payStatus=='10'" style="color:#F56C6C">未支付</span>
                    <span v-else-if="row.payStatus=='15'" style="color:#E6A23C">部分支付</span>
                    <span v-else-if="row.payStatus=='20'" style="color:#67C23A">已支付</span>
                    <span v-else-if="row.payStatus=='30'" style="color:#909399">已关闭</span>
                    <span v-else-if="row.payStatus=='40'" style="color:#909399">已退款</span>
                </template>
            </el-table-column>
            <el-table-column prop="payTime" label="支付时间" width="180"></el-table-column>
            <el-table-column prop="payName" label="支付方式" ></el-table-column>
            <el-table-column prop="payee" label="收款方" ></el-table-column>
        </el-table>
        <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
    </div>
</template>
<script>
import billPushApi from "@/api/business/billPush";
import companyInfoApi from "@/api/base/companyInfo";
import companyPaymentApi from "@/api/base/companyPayment";
import pageUtil from "@/utils/page";
import SelectTree from "@/components/SelectTree";
import "nprogress/nprogress.css"; // progress bar style

export default {
    name: "businessBillPushStatisticsList",
    data() {
        var self = this;

        return {
            queryModel: {
                name:"",
                companyId:"",
                status: "",
                payName:"",
                collectionAccount:"",
                pushTimeRange: "",
                payTimeRange:"",
                payStatus:"",
                invoiced: null,
                invoiceType:"",
            },
            treeData: [],
            loading: false,
            statusList:[
                {
                    id:"0",
                    name:"未推送",
                },
                {
                    id:"1",
                    name:"已推送",
                },
                {
                    id:"2",
                    name:"推送失败",
                },
                {      
                    id:"3",
                    name:"已作废",
                },
                {      
                    id:"4",
                    name:"已完成",
                }
            ],
            tableData: [],
            invoiceTypeList:[],
            payStatusList:[
                {
                    id:"10",
                    name:"未支付",
                },
                {
                    id:"15",
                    name:"部分支付",
                },
                {
                    id:"20",
                    name:"已支付",
                },
                {      
                    id:"30",
                    name:"已关闭",
                },
                {      
                    id:"40",
                    name:"已退款",
                }
            ],
            payNameList:[
                {
                    id:"wechat",
                    name:"微信",
                },
                {
                    id:"alipay",
                    name:"支付宝",
                },
                {
                    id:"transfer",
                    name:"对公转账"
                },
                {
                    id:"cash",
                    name:"现金支付"
                },
                {
                    id:"individual",
                    name:"个人账户"
                }
            ],
            collectionAccountList:[],
            companyList:[],
            pageIndex: 1,
            pageSize: 10,
            totalPages: 0,
            totalElements: 0,
            pageSizeList: [10, 20, 30],
            multipleSelection: [],
            showModal: false,
            showModal2: false,
            showModal3:false,
            props: {
                // 配置项（必选）
                value: "id",
                label: "name",
                children: "children"
            },
            tableHeight: 400,
            queryFormVisible: true
        }
    },
    created() {        
        this.loadTree();

        // companyInfoApi.treeList().then((response)=>{
        //     var jsonData = response.data;
        //     this.companyList = jsonData.data;
        // });

        companyPaymentApi.list().then((response)=>{
            var jsonData = response.data;
            this.collectionAccountList = jsonData.data;
        });

        billPushApi.getInvoiceTypeList().then((response)=>{
            var jsonData = response.data;
            this.invoiceTypeList = jsonData.data;
        });

        setTimeout(()=>{
            this.tableHeight = pageUtil.autoAdjustHeight(this.$refs.formTable.$el);
        },1000);
    },
    methods: {
        indexMethod(index) {
            return (this.pageIndex - 1) * this.pageSize + (index + 1);
        },
        toggleQueryForm(){
            this.queryFormVisible = !this.queryFormVisible;

            this.$nextTick(()=>{
                this.tableHeight = pageUtil.autoAdjustHeight(this.$refs.formTable.$el);
            });
        },
        changePage(pageIndex,exportFlag) {
            var self = this;

            self.loading = true;

            self.pageIndex = pageIndex;
            var formData = new FormData();

            formData.append("pageIndex", self.pageIndex);

            if(self.queryModel.companyId!=null){
                formData.append("companyId", self.queryModel.companyId);
            }

            formData.append("payName", self.queryModel.payName);

            formData.append("payTimeRange", self.queryModel.payTimeRange);
            formData.append("payStatus", self.queryModel.payStatus);

            formData.append("invoiceType", self.queryModel.invoiceType);

            if(self.queryModel.invoiced!=null){
                formData.append("invoiced", self.queryModel.invoiced);
            }

            if(exportFlag!=null){
                formData.append("exportFlag", exportFlag);
                formData.append("pageSize", 10000);
            }
            else{
                formData.append("pageSize", self.pageSize);
            }


            billPushApi.billPushGoodStatisticsList(formData)
                .then(function(response) {
                    self.loading = false;

                    var jsonData = response.data;

                    if(jsonData.result) {
                        if(exportFlag){

                            //导出
                            self.$message({
                                showClose: true,
                                type: "success",
                                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                                dangerouslyUseHTMLString: true,
                                duration: 30000
                            });
                        }
                        else{
                            //分页查看
                            var page = jsonData.data;

                            self.tableData = page.data;
                            self.totalPages = page.totalPages;
                            self.totalElements = page.recordsTotal;
                        }
                    }
                    else{
                        self.$message.warning(jsonData.message);
                    }
                })
                .catch(error => {
                    self.loading = false;
                    // self.$message.error(error + "");
                });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        exportXls() {
            this.changePage(1,true);
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        loadTree() {
            companyInfoApi.treeList().then(resp => {
                var jsonData = resp.data;

                if (jsonData.result) {
                this.treeData = jsonData.data;
                } else {
                this.$message.error(jsonData.message + "");
                }
            });
        },

    },
    mounted: function() {
        this.changePage(1);
    },
    components: {
        "el-select-tree": SelectTree
    }
}
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
.link-span{
    margin-right: 30px;
}
.span-view{
    margin-left: 10px;
}
</style>